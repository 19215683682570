<div class="container" tabindex="0">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="25vw">
    <h2 ngClass="doccount"  ngClass.md="doccount-md" tabindex="0">{{ docCount | number }} documents</h2>
    <div *ngIf="displayDateRange()"> <span class="lbl-prefix">Date Published </span><span class="datePublished">{{ getDateRange() }}</span></div>
  </div>    
  <div class="resultItem" *ngFor="let item of results; let i = index" tabindex="0" (keyup.enter)="openDocView(item)" role="link">
    <div ngClass="title" ngClass.md="title-md" > <span class="title-link" (click)="openDocView(item)" [innerHTML]="getSantizedTitle(item)"></span></div>
    <div [ngClass]="(results.length-1 === i && results.length > 39) ? 'citation' : 'citation border'" [ngClass.md]="(results.length-1 === i && results.length > 39) ? 'citation-md' : 'citation-md border'" >{{ getCitation(item) }}</div>
    <div ngClass='lastResultNote' ngClass='lastResultNote-md' *ngIf="(results.length-1 === i && results.length > 39)">
        This list is a preview of the first 40 results from your dataset.
    </div>
  </div>
</div>
